export function FirstSteps4K2223(child, currentUser) {
  var dob = formatDate(child.birthday);
  var schoolDistrict = currentUser.schoolDistrict;
  if (schoolDistrict == "Lex-Rich 5") {
    schoolDistrict = "Lexington/Richland 5";
  }
  var prefillFields = {
    WhatIsTheBestEmailAddressToReachYouFirstSteps4KWillUseThisEmailAddressToCommunicateWithYou:
      currentUser.email,
    WhatIsYourCellPhoneNumberFirstSteps4KWillUseThisCellPhoneToCommunicateWithYou:
      currentUser.phone,
    Child: {
      FirstName: child.firstName,
      MiddleName: child.middleName,
      LastName: child.lastName,
      SuffixIfAny2: child.suffix,
      FirstNamesDateOfBirth: dob,
    },
    PrimaryGuardiansName2: {
      FirstName: currentUser.firstName,
      LastName: currentUser.lastName,
      SuffixIfAny: currentUser.suffix,
    },
    PhysicalAddressyourResidence: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      PostalCode: currentUser.zipcode,
    },
    WhatCountyDoYouLiveIn: currentUser.county,
    WhatSchoolDistrictIsYourPhysicalAddressLocatedIn2: schoolDistrict,
    AdditionalInformationForFamilyMembers: {
      FormChildFirstNamesInformation: {
        Gender: child.gender,
      },
      FormPrimaryGuardiansName2FirstNamesInformation: {
        CellPhone: currentUser.phone,
        Email: currentUser.email,
      },
    },
  };

  return prefillFields;
}

export function FirstSteps4K2324(child, currentUser) {
  var dob = formatDate(child.birthday);
  var schoolDistrict = currentUser.schoolDistrict;
  if (schoolDistrict == "Lex-Rich 5") {
    schoolDistrict = "Lexington/Richland 5";
  }

  var prefillFields = {
    WhatIsYourPrimaryEmailAddressApplicationStatusUpdatesWillBeSentToThisAddress: currentUser.email,
    WhatIsYourCellPhoneNumberFirstSteps4KWillUseThisCellPhoneToCommunicateWithYou: currentUser.phone,
    WhatSchoolDistrictIsYourPhysicalAddressLocatedIn: schoolDistrict,
    WhatCountyDoYouLiveIn2: currentUser.county,
    PhysicalAddressyourResidence2 : {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        PostalCode: currentUser.zipcode
    },
    Child : {
        FirstName: child.firstName,
        MiddleName: child.middleName,
        LastName: child.lastName,
        SuffixIfAny2: child.suffix,
        FirstNamesDateOfBirth: dob
    },
    PrimaryGuardiansInformation : {
        FirstName: currentUser.firstName,
        MiddleName: currentUser.middleName,
        LastName: currentUser.lastName,
        SuffixIfAny2: currentUser.suffix,
        Email: currentUser.email,
        CellPhone: currentUser.phone
    }
  };

  return prefillFields;
}

export function FirstSteps4K2425(child, currentUser) {
  var dob = formatDate(child.birthday);
  var schoolDistrict = currentUser.schoolDistrict;
  if (schoolDistrict == "Lex-Rich 5") {
    schoolDistrict = "Lexington/Richland 5";
  }

  var prefillFields = {
    WhatIsYourPrimaryEmailAddressApplicationStatusUpdatesWillBeSentToThisAddress: currentUser.email,
    WhatIsYourCellPhoneNumberFirstSteps4KWillUseThisCellPhoneToCommunicateWithYou: currentUser.phone,
    WhatSchoolDistrictIsYourPhysicalAddressLocatedIn: schoolDistrict,
    WhatCountyDoYouLiveIn2: currentUser.county,
    PhysicalAddressyourResidence2 : {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        PostalCode: currentUser.zipcode
    },
    Child : {
        FirstName: child.firstName,
        MiddleName: child.middleName,
        LastName: child.lastName,
        SuffixIfAny2: child.suffix,
        FirstNamesDateOfBirth: dob
    },
    PrimaryGuardiansInformation : {
        FirstName: currentUser.firstName,
        MiddleName: currentUser.middleName,
        LastName: currentUser.lastName,
        SuffixIfAny2: currentUser.suffix,
        Email: currentUser.email,
        CellPhone: currentUser.phone
    }
  };

  return prefillFields;
}

export function BabyNet(child, currentUser) {
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ChildInformation: {
      ChildsFirstName: child.firstName,
      ChildsMiddleName: child.middleName,
      ChildsLastName: child.lastName,
      ChildsSuffix: child.suffix,
      ChildsDateOfBirth: dob,
      ChildsGender: child.gender,
      Address: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
    },
    ParentGuardianInformation: {
      ParentGuardianFirstName: currentUser.firstName,
      ParentGuardianMiddleName: currentUser.middleName,
      ParentGuardianLastName: currentUser.lastName,
      ParentGuardianSuffix: currentUser.suffix,
      ParentGuardianPhone: currentUser.phone,
      ParentGuardianEmailAddress: currentUser.email,
    },
    ReferralSourceInformation: {
      ReferralSourceName: currentUser.firstName + " " + currentUser.lastName,
      ReferralSourcePhone: currentUser.phone,
      ReferralSourceAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
      ReferralSourceEmail: currentUser.email,
    },
  };

  return prefillFields;
}

export function CERDEP(child, currentUser) {
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ChildInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName,
        Suffix: child.suffix,
      },

      DateOfBirth: dob,
      Sex: child.gender,
      HomeAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
    },
    ParentGuardianInformation: {
      PrimaryGuardianInformation: {
        Name: {
          First: currentUser.firstName,
          Last: currentUser.lastName,
          Suffix: currentUser.suffix,
        },
        Address: {
          Line1: currentUser.addressLine1,
          Line2: currentUser.addressLine2,
          City: currentUser.city,
          State: currentUser.state,
          PostalCode: currentUser.zipcode,
        },
        DaytimePhone: currentUser.phone,
        Email: currentUser.email,
      },
    },
  };

  return prefillFields;
}

export function Voucher(checkedChildren, currentUser, children) {
  var childrenPrefill = [];
  var schoolDistrict = currentUser.schoolDistrict;
  if (schoolDistrict == "Lex-Rich 5") {
    schoolDistrict = "Lexington/Richland 5";
  }
  for (var i = 0; i < checkedChildren.length; i++) {
    var selectedChild = children[checkedChildren[i]];
    var child = {
      ChildsFirstName: selectedChild.firstName,
      ChildsLastName: selectedChild.lastName,
      Birthdate: formatDate(selectedChild.birthday),
      AdditionalInformation: {
        SchoolDistrict: schoolDistrict
      }
    };
    childrenPrefill.push(child);
  }
  var prefillFields = {
    TellUsWhoYouAreAndWhereYouLive: {
      FirstName: currentUser.firstName,
      MiddleInitial: currentUser.middleName,
      LastName: currentUser.lastName,
      ResidenceAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
      CountyOfResidence: currentUser.county,
      CellPhone: currentUser.phone,
      Email: currentUser.email
    },
    TellUsAboutTheChildrenWhoNeedChildCareServices: childrenPrefill,
  };

  return prefillFields;
}

export function WIC(checkedChildren, currentUser, children) {
  var childrenPrefill = [];
  for (var i = 0; i < checkedChildren.length; i++) {
    var selectedChild = children[checkedChildren[i]];
    var child = {
      Name: {
        First: selectedChild.firstName,
        Middle: selectedChild.middleName,
        Last: selectedChild.lastName,
        Suffix: selectedChild.suffix,
      },
      Birthday: formatDate(selectedChild.birthday),
      Gender: selectedChild.gender,
    };
    childrenPrefill.push(child);
  }

  var prefillFields = {
    YourName: {
      First: currentUser.firstName,
      Middle: currentUser.middleName,
      Last: currentUser.lastName,
      Suffix: currentUser.suffix,
    },
    YourAddress: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      State: currentUser.state,
      PostalCode: currentUser.zipcode,
    },
    County: currentUser.county,
    YourPhone: currentUser.phone,
    YourEmail: currentUser.email,
    YourChildren: childrenPrefill,
  };

  return prefillFields;
}

export function FSPartnerships(checkedChildren, currentUser, children, subprograms) {
  var childrenPrefill = [];
  for (var i = 0; i < checkedChildren.length; i++) {
    var selectedChild = children[checkedChildren[i]];
    console.log("selectedChild", selectedChild);
    var child = {
      Name: {
        First: selectedChild.firstName,
        Middle: selectedChild.middleName,
        Last: selectedChild.lastName,
        Suffix: selectedChild.suffix,
      },
      Birthdate: formatDate(selectedChild.birthday),
      Gender: selectedChild.gender,
    };
    childrenPrefill.push(child);
  }
  var prefillFields = {
    YourInformation: {
      YourName: {
        First: currentUser.firstName,
        Middle: currentUser.middleName,
        Last: currentUser.lastName,
        Suffix: currentUser.suffix,
      },
      YourAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
      YourEmail: currentUser.email,
      YourPhoneNumber: currentUser.phone,
      WhatCountyDoYouLiveIn: currentUser.county,
      Programs: subprograms
    },
    YourChildren: childrenPrefill,
  };

  return prefillFields;
}
export function FSPartnershipsUpdated(checkedChildren, currentUser, children, subprograms) {
  var childrenPrefill = [];
  for (var i = 0; i < checkedChildren.length; i++) {
    var selectedChild = children[checkedChildren[i]];
    console.log("selectedChild", selectedChild);
    var child = {
      Name: {
        First: selectedChild.firstName,
        Middle: selectedChild.middleName,
        Last: selectedChild.lastName,
        Suffix: selectedChild.suffix,
      },
      Birthdate: formatDate(selectedChild.birthday),
      Section: {
        Gender: selectedChild.gender
      }
    };
    childrenPrefill.push(child);
  }
  var prefillFields = {
    YourInformation: {
      YourName: {
        First: currentUser.firstName,
        Middle: currentUser.middleName,
        Last: currentUser.lastName,
        Suffix: currentUser.suffix,
      },
      YourAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
      YourEmail: currentUser.email,
      YourPhoneNumber: currentUser.phone,
      WhatCountyDoYouLiveIn: currentUser.county,
      SchoolDistrict: currentUser.schoolDistrict,
      Programs: subprograms
    },
    YourChildren: childrenPrefill,
  };

  return prefillFields;
}

export function WilliamsburgSD(child, currentUser)
{
  var dob = formatDate(child.birthday);
  var prefillFields = {
    Name: {
      First: child.firstName,
      Middle: child.middleName,
      Last: child.lastName,
      Suffix: child.suffix,
    },
    DateOfBirth: dob,
    PermanentAddress: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      State: currentUser.state,
      PostalCode: currentUser.zipcode,
    },
    YourName: currentUser.firstName + " " + currentUser.lastName,
    ChildInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName,
        Suffix: child.suffix,
      },
      DateOfBirth: dob,
      Sex: child.gender,
      HomeAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      }
    },
    ParentGuardianInformation: {
      PrimaryGuardianInformation: {
        Name: {
          First: currentUser.firstName,
          Last: currentUser.lastName,
          Suffix: currentUser.suffix,
        },
        Address: {
          Line1: currentUser.addressLine1,
          Line2: currentUser.addressLine2,
          City: currentUser.city,
          State: currentUser.state,
          PostalCode: currentUser.zipcode,
        },
        DaytimePhone: currentUser.phone,
        Email: currentUser.email,
      },
    },
  };

  return prefillFields;
}

export function AllendaleSD(child, currentUser) {
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ChildInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName,
        Suffix: child.suffix,
      },

      DateOfBirth: dob,
      Sex: child.gender,
      HomeAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
    },
    ParentGuardianInformation: {
      PrimaryGuardianInformation: {
        Name: {
          First: currentUser.firstName,
          Last: currentUser.lastName,
          Suffix: currentUser.suffix,
        },
        Address: {
          Line1: currentUser.addressLine1,
          Line2: currentUser.addressLine2,
          City: currentUser.city,
          State: currentUser.state,
          PostalCode: currentUser.zipcode,
        },
        DaytimePhone: currentUser.phone,
        Email: currentUser.email,
      },
    },
  };

  return prefillFields;
}

export function BeaufortCountySD(child, currentUser, subprograms){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ParentGuardianInformation: {
      Name: {
        First: currentUser.firstName,
        Last: currentUser.lastName,
        Suffix: currentUser.suffix,
      },
      Address: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
      Phone: currentUser.phone,
      Email: currentUser.email,
    },
    ChildsInformation : {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName,
        Suffix: child.suffix,
      },
      Birthday: dob,
      Gender: child.gender,
    },
    WhatProgramsAreYouInterestedIn : subprograms
  };

  return prefillFields;
}

export function YorkSD(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ClientFamilyInformation: {
      Name: {
        First: currentUser.firstName,
        Last: currentUser.lastName
      },
      Address: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
      CellPhone: currentUser.phone,
      EmailAddress: currentUser.email,
    },
    ChildsInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName
      },
      Birthdate: dob
    }
  };

  return prefillFields;
}

export function BeaufortJasperPAT(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ContactNumbers: {
      Phone1: currentUser.phone
    },
    Address: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      State: currentUser.state,
      PostalCode: currentUser.zipcode,
    },
    County: currentUser.county,
    ChildsInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName
      },
      DateOfBirth: dob
    }
  };

  return prefillFields;
}

export function CarolinaHealth(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ParentCaregiverInformation : {
      Name: {
        First: currentUser.firstName,
        Last: currentUser.lastName
      }
    },
    ContactInformation: {
      Phone: currentUser.phone,
      Email: currentUser.email
    },
    Address: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      State: currentUser.state,
      PostalCode: currentUser.zipcode,
    },
    County: currentUser.county,
    ChildsInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName
      },
      DateOfBirth: dob
    }
  };

  return prefillFields;
}

export function EauClairePAT(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ParentCaregiverInformation : {
      Name: {
        First: currentUser.firstName,
        Last: currentUser.lastName
      }
    },
    ContactInformation: {
      Phone: currentUser.phone,
      Email: currentUser.email
    },
    Address: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      State: currentUser.state,
      PostalCode: currentUser.zipcode,
    },
    County: currentUser.county,
    ChildsInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName
      },
      DateOfBirth: dob
    }
  };

  return prefillFields;
}

export function LowCountryHFA(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ParentCaregiverInformation : {
      Name: {
        First: currentUser.firstName,
        Last: currentUser.lastName
      }
    },
    ContactInformation: {
      Phone: currentUser.phone,
      Email: currentUser.email
    },
    Address: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      State: currentUser.state,
      PostalCode: currentUser.zipcode,
    },
    County: currentUser.county,
    ChildsInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName
      },
      DateOfBirth: dob
    }
  };

  return prefillFields;
}

export function McLeodNFP(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ParentCaregiverInformation : {
      Name: {
        First: currentUser.firstName,
        Last: currentUser.lastName
      }
    },
    ContactInformation: {
      Phone: currentUser.phone,
      Email: currentUser.email
    },
    Address: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      State: currentUser.state,
      PostalCode: currentUser.zipcode,
    },
    County: currentUser.county,
    ChildsInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName
      },
      DateOfBirth: dob
    }
  };

  return prefillFields;
}

export function ParentingPlaceHFA(child, currentUser){
  var prefillFields = {
    ClientInformation: {
      Name : {
        First: currentUser.firstName,
        Last: currentUser.lastName
      },
      Phone: currentUser.phone,
      Email: currentUser.email,
      MailingAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      }
    }
  }

  return prefillFields;
}

export function PrismaPAT(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ParentCaregiverInformation : {
      Name: {
        First: currentUser.firstName,
        Last: currentUser.lastName
      }
    },
    ContactInformation: {
      Phone: currentUser.phone,
      Email: currentUser.email
    },
    Address: {
      Line1: currentUser.addressLine1,
      Line2: currentUser.addressLine2,
      City: currentUser.city,
      State: currentUser.state,
      PostalCode: currentUser.zipcode,
    },
    County: currentUser.county,
    ChildsInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName
      },
      DateOfBirth: dob
    }
  };

  return prefillFields;
}

export function AbbevilleSD(child, currentUser) {
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ChildInformation: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName,
        Suffix: child.suffix,
      },

      DateOfBirth: dob,
      Sex: child.gender,
      HomeAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
    },
    ParentGuardianInformation: {
      PrimaryGuardianInformation: {
        Name: {
          First: currentUser.firstName,
          Last: currentUser.lastName,
          Suffix: currentUser.suffix,
        },
        Address: {
          Line1: currentUser.addressLine1,
          Line2: currentUser.addressLine2,
          City: currentUser.city,
          State: currentUser.state,
          PostalCode: currentUser.zipcode,
        },
        DaytimePhone: currentUser.phone,
        Email: currentUser.email,
      },
    },
  };

  return prefillFields;
}

export function LancasterHS(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ParentGuardian: {
      Name: {
        First: currentUser.firstName,
        Middle: currentUser.middleName,
        Last: currentUser.lastName
      },
      Email: currentUser.email,
      ConfirmEmail: currentUser.email,
      MobilePhone: currentUser.phone
    },
    Address: {
      LivingAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      }
    },
    ChildApplicant: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName,
        Suffix: child.suffix,
      },
      Birthday: dob,
      Gender: child.gender
    }
  };

  return prefillFields;
}

export function WaccamawHS(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ParentGuardian: {
      Name: {
        First: currentUser.firstName,
        Middle: currentUser.middleName,
        Last: currentUser.lastName
      },
      EmailAddress: currentUser.email,
      ConfirmEmailAddress: currentUser.email,
      MobilePhone: currentUser.phone
    },
    Address: {
      LivingAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      }
    },
    ChildApplicant: {
      Name: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName,
        Suffix: child.suffix,
      },
      Birthday: dob,
      Gender: child.gender
    }
  };

  return prefillFields;
}

export function PiedmontCAA(child, currentUser){
  var dob = formatDate(child.birthday);
  var prefillFields = {
    ApplicantsInformation: {
      LegalName: {
        First: child.firstName,
        Middle: child.middleName,
        Last: child.lastName,
        Suffix: child.suffix
      },
      DateOfBirth: dob,
      Sex: child.gender
    },
    FamilyInformation: {
      ParentGuardian1: {
        Name: {
          First: currentUser.firstName,
          Middle: currentUser.middleName,
          Last: currentUser.lastName,
          Suffix: currentUser.suffix
        }
      },
      LivingAddress: {
        Line1: currentUser.addressLine1,
        Line2: currentUser.addressLine2,
        City: currentUser.city,
        State: currentUser.state,
        PostalCode: currentUser.zipcode,
      },
      EmailAddress: currentUser.email,
      CellPhone: currentUser.phone
    },
    YourCounty: currentUser.county,
    YourSchoolDistrict: currentUser.schoolDistrict
  };
  
  return prefillFields;
}

function formatDate(date) {
  var d = new Date(date + "  00:00:00"),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
